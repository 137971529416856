import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import GameCanvas from './components/GameCanvas';
import RoomSelector from './components/RoomSelector';
import ChatWidget from './components/ChatWidget';
import Header from './components/Header';
import Leaderboard from './components/LeaderBoard';
import DisconnectionModal from './components/DisconnectionModal';


import './App.css';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

const chains = [arbitrum, mainnet, polygon]
const projectId = '917c197367a7f5c8a98ea363af307d94'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

let mockLeaderBoardData = [
  { id: 5, name: "Player5", score: 28500 },
  { id: 1, name: "Player1", score: 25500 },
  { id: 10, name: "Player10", score: 22500 },
  { id: 9, name: "Player9", score: 17000 },
  { id: 8, name: "Player8", score: 12000 },
  { id: 3, name: "Player3", score: 7000 },
  { id: 6, name: "Player6", score: 0 },
  { id: 7, name: "Player7", score: -7000 },
  { id: 2, name: "Player2", score: -12000 },
  { id: 4, name: "Player4", score: -45000 }
]


function App() {
  const [LeaderBoardIsOpen, setLeaderBoardIsOpen] = useState(false); // by default the leaderboard is open
  const [LeaderBoardData, setLeaderBoardData] = useState([]);
  const [isDisconnected, setIsDisconnected] = useState(false);

  window.mySocket.on('update leaderboard', (data) => {
    let leaderboard = data.leaderboard.sort((a, b) => b.score - a.score);

    setLeaderBoardData(leaderboard);
    setLeaderBoardIsOpen(true);
  })

  window.mySocket.on('disconnect', (data) => {
    console.log('disconnected');
    setIsDisconnected(true);
  })

  const onClose = () => {
    setLeaderBoardIsOpen(false);
  };

  return (
    <div className="container-fluid">
      <Header />

      <WagmiConfig config={wagmiConfig}>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />

      <DisconnectionModal isVisible={isDisconnected} />

      {LeaderBoardIsOpen &&
        <Leaderboard
          roundData={LeaderBoardData}
          overallData={LeaderBoardData}
          onClose={onClose}
        />
      }

      <div className="row">
        <div className="col-md-8">
          <GameCanvas />
        </div>
        <div className="col-md-4">
          <RoomSelector />
          <ChatWidget />
        </div>
      </div>
    </div>
  );
}

export default App;
