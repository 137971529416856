import React, { useEffect } from 'react';

import './GameCanvas.css';

function resizeCanvas(canvasElement, containerElement) {
    console.log(containerElement);
    const containerWidth = containerElement.offsetWidth;
    const scale = containerWidth / 1000;  // Assuming 1000px is the width of your canvas

    if (scale < 1) { // If the container is smaller than the canvas
        canvasElement.style.transform = `scale(${scale})`;
        canvasElement.style.transformOrigin = "top left"; // Make sure it scales from the top left corner
    } else {
        // If the container is bigger than or equal to the canvas size, reset the scale.
        canvasElement.style.transform = "scale(1)";
    }
}

window.addEventListener('resize', function () {
    const canvasOne = document.getElementById('gameCanvas');
    const canvasTwo = document.getElementById('overlayCanvas');
    const containerElement = document.getElementById('canvasContainer');
    resizeCanvas(canvasOne, containerElement);
    resizeCanvas(canvasTwo, containerElement);
});

// Also call it initially to set the correct scale on page load

function GameCanvas() {

    useEffect(() => {
        resizeCanvas(document.getElementById('gameCanvas'), document.getElementById('canvasContainer'));
        resizeCanvas(document.getElementById('overlayCanvas'), document.getElementById('canvasContainer'));
    }, []);

    return (
        <div className="canvasContainer" id="canvasContainer">
            <canvas id="overlayCanvas" width="1000" height="1000"></canvas>
            <canvas id="gameCanvas" width="1000" height="1000"></canvas>
        </div>
    );
}

export default GameCanvas;
