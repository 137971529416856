import React from 'react';
import './DisconnectionModal.css'

const DisconnectionModal = ({ isVisible }) => {
    if (!isVisible) return null;

    const handleReconnect = () => {
        // Simply refresh the page to attempt a reconnection
        window.location.reload();
    };

    return (
        <div className="disconnection-modal">
            <div className="modal-content">
                <h3>Disconnected</h3>
                <p>You've been disconnected from the server.</p>
                <button onClick={handleReconnect}>Reconnect</button>
            </div>
        </div>
    );
};

export default DisconnectionModal;
