import React from 'react';

function RoomSelector() {
  return (
    <div>
      {/* Your room selection logic here */}
    </div>
  );
}

export default RoomSelector;
