import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import socketIOClient from 'socket.io-client';

import './ChatWidget.css';

const ENDPOINT = process.env.REACT_APP_SOCKET_ORIGIN;

const socket = socketIOClient(ENDPOINT, { transports: ['websocket'], reconnection: false, reconnectionAttempts: 0 });

window.mySocket = socket;

const rooms = ['Copper Court', 'Silver Sanctuary', 'Gold Gallery'];

let defaultRoomState = {
    id: null,
    users: [],
    stakes: 0,
    minPlayersToStart: 2,
    queueTime: 30, // time between user minimum reached and round begins
    round: {
        isRunning: false,
        queued: false,
        players: [], // in the round
        results: [],
        coinsCollected: {},
        startTime: 0
    },
    mapItems: []
}

function groupMessages(messages) {

    console.log(messages);
    const groupedMessages = [];
    let currentGroup;

    messages.forEach((message) => {
        if (currentGroup && currentGroup.userId === message.userId) {
            currentGroup.messages.push(message);
        } else {
            if (currentGroup) {
                groupedMessages.push(currentGroup);
            }
            currentGroup = {
                userId: message.userId,
                username: message.username,
                avatarURL: message.avatarURL || "https://placehold.co/40x40",
                messages: [message],
            };
        }
    });

    if (currentGroup) {
        groupedMessages.push(currentGroup);
    }

    console.log(groupedMessages);

    return groupedMessages;
}

function ChatWidget() {
    const [room, setRoom] = useState('Copper Court');
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [joinedRound, setJoinedRound] = useState(false);
    const [gameState, setGameState] = useState(defaultRoomState);
    const [startTime, setStartTime] = useState(null); // Timestamp in milliseconds for when the round starts
    const [timeLeft, setTimeLeft] = useState(0);

    let userId;
    let stake = 1000;

    const roomRef = useRef(room);
    const chatboxRef = useRef(null);

    // State to keep track of the previous scrollHeight
    const [prevScrollHeight, setPrevScrollHeight] = useState(0);

    useLayoutEffect(() => {
        const { scrollTop, scrollHeight, clientHeight } = chatboxRef.current;

        // Check if it was previously at the bottom
        const wasScrolledToBottom = prevScrollHeight && scrollTop === prevScrollHeight - clientHeight;

        setPrevScrollHeight(scrollHeight);

        if (wasScrolledToBottom) {
            chatboxRef.current.scrollTop = scrollHeight - clientHeight;
        }

    }, [messages]);


    useEffect(() => {
        handleJoinRoom('Copper Court');

        socket.on('connect', () => {

            console.log(`GOT CONNECT`)
            userId = socket.id;

            console.log(`Our UserId: ${userId}`)

            setInterval(() => {
                socket.emit('ping', Date.now());
            }, 1000);
        })

        socket.on('pong', (timestamp) => {
            const latency = Date.now() - timestamp;
            console.log(`Latency is ${latency} ms`);
        });

        socket.on('chat message', (data) => {
            console.log(`Received chat message`, data);
            console.log(`Current room: ${room}`);

            if (data.roomName === roomRef.current) {
                setMessages((prevMessages) => [...prevMessages, data.message]);
            }
        });

        socket.on('load history', (history) => {
            setMessages(history);
        });

        socket.on('updateGameState', (data) => {
            console.log(data);
            console.log(`Checking if we're in the round.`, `Our user: ${userId}`)
            let userInRound = false;
            for (let player of data.round.players) {
                console.log(`Joined user id: ${player.id}`);
                if (player.id == userId) {
                    console.log(`Yes we're in the round!`)
                    userInRound = true;
                    break;
                }
            }

            setGameState(data);
            setJoinedRound(userInRound);
        })

        socket.on('round queued', (data) => {
            setStartTime(data.startTime); // Assuming data.startTime is in milliseconds
        });

        socket.on('update users', (users) => {
            setUsers(users);
        });

        return () => {
            socket.off('chat message');
        };
    }, []);

    useEffect(() => {
        console.log(`Checking start time`)
        if (startTime) {
            // Calculate initial time left
            const initialTimeLeft = startTime - Math.floor((Date.now()) / 1000);

            console.log(initialTimeLeft);
            setTimeLeft(initialTimeLeft);

            const interval = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft > 0) {
                        return prevTimeLeft - 1;
                    } else {
                        clearInterval(interval); // Clear the interval if time is up
                        return 0;
                    }
                });
            }, 1000);

            return () => clearInterval(interval); // Clear the interval when component unmounts or startTime changes
        }
    }, [startTime]);


    const handleSendMessage = () => {
        console.log(`Sending message from Room: ${room}`);
        socket.emit('chat message', {
            roomName: room,
            message: currentMessage
        });
        setCurrentMessage('');
    };

    const handleJoinRoom = (newRoom) => {
        if (room) {
            socket.emit('leave room', room);
        }
        setMessages([]); // Clear messages from the previous room
        roomRef.current = newRoom;
        socket.emit('join room', newRoom);
        setRoom(newRoom);
    };

    const handleJoinLeaveRound = () => {
        socket.emit('join leave round', {});
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && currentMessage.trim() !== '') {
            handleSendMessage();
            e.preventDefault();  // Prevents the default behavior of the enter key (newline in some cases)
        }
    };

    const isScrolledToBottom = () => {
        const { scrollHeight, scrollTop, clientHeight } = chatboxRef.current;
        return scrollTop === (scrollHeight - clientHeight);
    };


    return (
        <div className="chat-container">
            <div className="room-list">
                <div className="selected-room">{room}</div>
                <div className="room-status">
                    {gameState.round.isRunning ?
                        <div className="in-progress">
                            <p>Round currently in progress.</p>
                        </div>
                        :

                        <div className="waiting-room">
                            <p>{gameState.round.players.length} players in the next round.</p>
                            {!gameState.round.queued ?
                                <p>Waiting for players...</p>
                                :
                                <p>Starting in: {timeLeft} seconds.</p>
                            }
                            <button
                                className="join-btn"
                                onClick={() => handleJoinLeaveRound()}
                            >
                                {!joinedRound ? `Join for ${gameState.stakes.toLocaleString("en-US")} coins` : "Leave Round"}
                            </button>
                        </div>

                    }
                </div>
                <div className="room-header">Rooms</div>
                <ul>
                    {rooms.map(r => (
                        <li key={r}>
                            <a
                                href="#!"
                                className={r === room ? 'active' : ''}
                                onClick={() => handleJoinRoom(r)}
                            >
                                {r}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="chat-content">
                <div className="row">
                    <div className="col-md-9 chat-content-container">
                        <div className="chatbox chat-box" ref={chatboxRef}>
                            {groupMessages(messages).map((group, idx) => (
                                <div key={idx} className="message-group">
                                    <img src={group.avatarURL || "https://placehold.co/40x40"} alt="User avatar" width="32" height="32" />
                                    <div className="messages">
                                        <div className="message-header">
                                            <span className="username">{group.username || "Anonymous"}</span>
                                            <span className="timestamp">{new Date(group.messages[0].timestamp * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</span>
                                        </div>
                                        {group.messages.map((message, mIdx) => (
                                            <div key={mIdx} className="individual-message">
                                                <span>{message.message}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="col-md-3 userlist-container">
                        <div className="userlist">
                            {users.map((user, idx) => (
                                <div key={idx}>{user.name}</div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="chat-input input-container">
                        <input
                            type="text"
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e)}
                            placeholder="Type your message..."
                            className="input-field"
                        />

                        <button onClick={handleSendMessage} className="send-button">Send</button>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default ChatWidget;
