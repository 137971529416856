// Header.js
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import './Header.css';

import { Web3Button } from '@web3modal/react'


const Header = () => {
    const [coinsBalance, setCoinsBalance] = useState(0);

    window.mySocket.on('updateBalance', (data) => {
        let myBalance = data.balance;

        setCoinsBalance(myBalance);
    })



    return (
        <div className="header-container">
            <div className="header-title">CoinCombat</div>
            <div className="coin-balance">
                Coin Balance: <span>{coinsBalance.toLocaleString("en-US")}</span> 🪙
            </div>
            <div className="header-login">
                {/* This is where the Wallet Connect button will go */}
                <Web3Button />
            </div>
        </div>
    );
};

export default Header;
