import './LeaderBoard.css'; // import the associated CSS

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

function LeaderboardModal({ roundData, overallData, onClose }) {
    const [view, setView] = useState('round'); // 'round' or 'overall'

    return (
        <div className="modal-container">
            <div className="leaderboard-modal">
                <div className="tabs">
                    <button onClick={() => setView('round')} className={view === 'round' ? 'active' : ''}>
                        Round Leaderboard
                    </button>
                    <button onClick={() => setView('overall')} className={view === 'overall' ? 'active' : ''}>
                        Overall Leaderboard
                    </button>
                </div>
                {view === 'round' ? <LeaderboardList data={roundData} /> : <LeaderboardList data={overallData} />}
                <button onClick={onClose} className="close-button">Play Again</button>
            </div>
        </div>

    );
}

function LeaderboardList({ data }) {
    return (
        <ul className="leaderboard-list">
            {data.map((player, index) => (
                <li key={player.id}>
                    <span className="leaderboard-position">
                        {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : index + 1}
                    </span>
                    <span className="leaderboard-name">{player.name}</span>
                    <span className={`leaderboard-score ${player.score >= 0 ? 'positive-score' : 'negative-score'}`}>
                        {player.score >= 0 ? "+" : ""}{player.score}
                    </span>
                </li>
            ))}
        </ul>
    );
}

export default LeaderboardModal;